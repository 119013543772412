// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-templates-appartement-js": () => import("./../../../src/templates/appartement.js" /* webpackChunkName: "component---src-templates-appartement-js" */)
}

